export default {
   // baseURL: 'http://localhost:18686'
   // baseURL:'http://54.223.240.188:8051', //正式环境ip/
   //  baseURL: 'https://oilgasjobai.com',
   // baseURL: 'https://cn.oilgasgpts.com'
   // baseURL: 'https://cn.oilgasgpts.com'
   //
   //测试服务器
   // baseURL: 'http://71.131.202.255:8787',
   // baseURLEN: 'http://71.131.202.255:8788'
   // //正式服务器
   baseURL: 'https://cn.oilgasgpts.com',
   baseURLEN: 'https://www.oilgasgpts.com'
}
