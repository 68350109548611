import Vue from "vue";


const animationMap = new WeakMap();
const ob = new IntersectionObserver((entries) => {
    for (const entry of entries) {
        if (entry.isIntersecting) {
            const animation = animationMap.get(entry.target);
            if (animation ) {
                animation.play();
                ob.unobserve(entry.target);
            }
        }
    }
});
// function isBelowViewport(el) {
//     const rect = el.getBoundingClientRect();
//     console.log("高度：",rect.top)
//     console.log("pingmu高度：",window.innerHeight)
//     return rect.top > window.innerHeight; // 判断元素是否在视口下方
// }

const vZoomIn = {
    bind(el, binding) {
        Vue.nextTick(() => {
            // 检查元素是否在视口下方，如果不在则不执行动画
            // if (!isBelowViewport(el)) {
            //     console.log("元素不在视口下方，返回");
            //     return;
            // }

            // 创建动画
            const animation = el.animate(
                [
                    {
                        transform: binding.value.scale || 'scale(0.8)',
                        opacity: 0, // 确保初始状态
                    },
                    {
                        transform: 'scale(1)',
                        opacity: 1, // 确保最终状态
                    }
                ],
                {
                    duration: binding.value.duration || 1000, // 默认时长为1000ms
                    easing: 'ease-in-out',
                    fill: 'forwards', // 停留在动画结束状态
                }
            );

            // 监听动画结束事件，确保动画结束后状态正确
            animation.onfinish = () => {
                // alert("12312312")

                // 使用 requestAnimationFrame 立即设置最终样式
                requestAnimationFrame(() => {
                    try {
                        el.style.transform = 'scale(1)';
                        el.style.opacity = '1';
                        console.log("动画完成并停留在最终状态");
                    } catch (err) {
                        console.error("设置最终样式时出错：", err);
                    }
                });
            };

            // 暂停动画，等待触发条件再播放
            animation.pause();
            el.style.transform = 'scale(1)';
            el.style.opacity = '1';
            animationMap.set(el, animation);

            // 开始观察元素进入视口
            ob.observe(el);
        });
    },

    unbind(el) {
        // 在指令解除绑定时取消观察
        ob.unobserve(el);
    }
};

export {
    vZoomIn
}
