<template>
  <div>
    <div v-if="model===false">
      <div ref="total" class="totalmakeHole">
        <div class="makeHole1">
          <div :style="{ height: '6vh', backgroundColor: scrollback ? 'rgb(245 244 246)' : 'rgb(245 244 246)' ,position:'fixed'}"
               class="topback">
            <headtip  :changeVisable="changeVisable" @showDialog="showPartner"></headtip>
          </div>
          <div @click="changeheadVisable">
            <kefu @toptop="topTop"></kefu>
<!--            <div class="screen1">-->
<!--              &lt;!&ndash;      logo  you气通GPT社区 合作伙伴  登录&ndash;&gt;-->

<!--              &lt;!&ndash;       油气通GPT 全球油气行业最专业的、开放的大模型人工智能云平台&ndash;&gt;-->
<!--              <div class="layer2">-->
<!--                <div v-sliden-instep="{duration:800}"  class="title1">-->
<!--                  <img loading="lazy" src="@/assets/AnxunLogo.png">-->
<!--                </div>-->
<!--                <div v-sliden-instep="{duration:1600}" class="title11" style="font-weight: bolder;" >智慧管网解决方案</div>-->
<!--                <div v-sliden-instep="{duration:2400}" class="title2" style="font-weight: bolder;;">-->
<!--                  构建“地图+模型+数据+业务”一体化系统，实现管网的数智化、可视化运营管理-->
<!--                </div>-->
<!--                <div v-sliden-instep="{duration:3000}" class="title4" style="font-weight: bolder;;">油气数智，简单易行</div>-->
<!--                <div class="titlle3" @click="clickConsult('油气通')" style="font-weight: bolder;;">-->
<!--                  产品咨询-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash;      平台大图&ndash;&gt;-->
<!--              <div class="layer3"></div>-->
<!--            </div>-->
            <Anxunheader :title1="'智慧管网解决方案'" :title2="' 构建“地图+模型+数据+业务”一体化系统，实现管网的数智化、可视化运营管理'" @clickConsult1="clickConsult('油气通')"></Anxunheader>
<!--            <div class="screenmake0">-->
<!--              <div class="heighLight">智慧管网解决方案</div>-->
<!--              <div class="heightLight2">-->
<!--                智慧管网解决方案基于GIS和BIM技术，结合物联网、大数据、云计算和人工智能，提供全面的管网运维管理。以“数据整合、应用融合、业务联动、智能管理”为核心，构建“地图+模型+数据+业务”一体化系统，<span-->
<!--                  style="color: rgb(255, 100, 0)">实现管网的数字化、在线化和智能化管理，解决传统管网可视性差、管理难度大、数据分散等问题，提升管网管理效率和智能化水平。</span>-->
<!--              </div>-->
<!--            </div>-->

            <div class="screenmake3" style="background-color: white">
              <div v-sliden-in="{duration:800}" class="heighLight" >
               您的收益
              </div>
              <div class="grid" >
                <image-up-text-down v-sliden-in="{duration:800 + 400*index}" v-for="(elemnt,index) in yourBenfits" :key="elemnt.title1"  :imagepath="require('@/assets'+elemnt.imagepath)" :title1="elemnt.title1" :title2="elemnt.title2"></image-up-text-down>
              </div>
            </div>
            <div class="screenmake6">
              <div v-sliden-instep="{duration:3000}" class="heighLight"> 业务痛点与挑战</div>
<!--              <div class="line"></div>-->
              <div v-sliden-instep="{duration:3000}" class="grid">
                <div class="card">
                  <div class="back">
                    <div class="imgDesign">
                      <img loading="lazy" src="@/assets/network03.png" style="border-radius: 9px">
                    </div>
                    <div class="titleDesign">
                      <div class="title1">数据应用与分析技术应用不足
                      </div>
                      <div class="title2">
                        管网监测技术逐步加强，但数据应用与分析技术应用仍有不足。
                      </div>
                    </div>
                  </div>


                </div>
                <div class="card">
                  <div class="back">
                    <div class="imgDesign">
                      <img loading="lazy" src="@/assets/network02.png" style="border-radius: 9px">
                    </div>
                    <div class="titleDesign">
                      <div class="title1">管网老化与安全隐患突出
                      </div>
                      <div class="title2">
                        管网老化与安全隐患问题突出，影响正常的供应服务，可能引发安全事故。
                      </div>
                    </div>
                  </div>


                </div>
                <div class="card">
                  <div class="back">
                    <div class="imgDesign">
                      <img loading="lazy" src="@/assets/network01.png" style="border-radius: 9px">
                    </div>
                    <div class="titleDesign">
                      <div class="title1">运维管理内容复杂
                      </div>
                      <div class="title2">
                        运维管理内容复杂，人工管理与记录工作繁重，工作效率低。
                      </div>
                    </div>
                  </div>


                </div>

              </div>

            </div>
            <div class="screenmake1" style="background-color: transparent">
              <div v-sliden-in="{duration:800}" class="heighLight"> 方案架构</div>
              <div v-sliden-in="{duration:800}" class="grid">
                <div class="card">
                  <div class="back">
                    <div class="imgDesign">
                      <el-tooltip class="item" content="点击放大，看得更清晰！" effect="dark" placement="top">
                        <img loading="lazy" src="@/assets/guanwang11.png"
                             style="border-radius: 9px" @click="showModalTrue(require('@/assets/guanwang11.png'))">
                      </el-tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div  class="screenmake5" style="background-color: white">
              <div v-sliden-in="{duration:800}" class="heighLight">
                核心功能
              </div>
              <div v-sliden-in="{duration:800}" class="grid">
                <div class="card">
                  <div class="back">

                    <div class="titleDesign">
                      <div class="title1">GIS+BIM一体化管理
                      </div>
                      <div class="title2">
                        <li>全生命周期管理：覆盖管网从设计到维护的全周期，确保数据一致。</li>
                        <li>可视化与监控：3D展示管网布局，实时监控设施状态。</li>
                        <li>精准定位与监控：支持设施精准定位和实时监控，快速识别异常。</li>
                      </div>
                    </div>
                  </div>


                </div>
                <div class="card">
                  <div class="back">

                    <div class="titleDesign">
                      <div class="title1">数据整合与应用融合
                      </div>
                      <div class="title2">
                        <li>多源数据整合：统一整合管网数据，消除信息孤岛，促进系统间数据共享。</li>
                        <li>大数据分析：深入分析实时与历史数据，支持智能调度和优化管理。</li>
                        <li>智能调度决策：基于大数据自动优化资源调度，多维度辅助决策，提升运营效率。</li>
                      </div>
                    </div>
                  </div>


                </div>
                <div class="card">
                  <div class="back">
                    <div class="titleDesign">
                      <div class="title1">数字化运维管理
                      </div>
                      <div class="title2">
                        <li>运营与维护：持续监控与预防性维护，减少故障，确保高效运行。</li>
                        <li>移动巡查：支持快速定位与响应，提升维护效率。</li>
                        <li>阴保监控：实时监测管网，预防腐蚀，确保结构安全，优化维护，降低成本。</li>
                        <li>光纤泄漏监测：实时检测微小泄漏，快速处理，降低风险。</li>
                        <li>人机巡检：高效巡检复杂区域，精准识别异常</li>

                      </div>
                    </div>
                  </div>
                </div>


              </div>

            </div>
            <div class="screenmake2" style="background-color: transparent">
              <div v-sliden-in="{duration:800}" class="heighLight"> 技术特色</div>
              <div v-sliden-in="{duration:800}" class="grid">
                <div class="card">
                  <div class="back">
                    <div class="imgDesign" style="width: 12vh">
                      <img loading="lazy" src="@/assets/guanwang1.png" style="border-radius: 9px">
                    </div>
                    <div class="titleDesign">
                      <div class="title1">全方位多维度可视化
                      </div>
                      <div class="title2">
                        利用GIS+BIM技 术，实现管网系统的全面可视化管理，从整体布局到局部细节实时呈现，提升管网的感知与监管能力。
                      </div>
                    </div>
                  </div>


                </div>
                <div class="card">
                  <div class="back">
                    <div class="imgDesign">
                      <img loading="lazy" src="@/assets/guanwang2.png" style="border-radius: 9px">
                    </div>
                    <div class="titleDesign">
                      <div class="title1">数据驱动的智能管理
                      </div>
                      <div class="title2">
                        通过大数据技术，综合分析管网各维度数据，提供智慧调度、故障预测和运营优化的科学决策支持。
                      </div>
                    </div>
                  </div>


                </div>
                <div class="card">
                  <div class="back">
                    <div class="imgDesign">
                      <img loading="lazy" src="@/assets/guanwang3.png" style="border-radius: 9px">
                    </div>
                    <div class="titleDesign">
                      <div class="title1">一体化多终端支持
                      </div>
                      <div class="title2">
                        采用一体化管理平台，支持大屏监测、Web管理、移动巡查等多终端应用，满足不同用户需求，提升管理效率。
                      </div>
                    </div>
                  </div>


                </div>
                <div class="card">
                  <div class="back">
                    <div class="imgDesign">
                      <img loading="lazy" src="@/assets/guanwang4.png" style="border-radius: 9px">
                    </div>
                    <div class="titleDesign">
                      <div class="title1">高效智能泄漏巡检
                      </div>
                      <div class="title2">
                        结合光纤泄漏监测和无人机巡检，构建高效监测体系，确保管网安全稳定，降低故障率。
                      </div>
                    </div>
                  </div>


                </div>


              </div>

            </div>
            <div class="screenmake4">
              <div v-sliden-in="{duration:800}" class="heighLight">成功案例</div>


              <div class="grid" style="margin-top: -0.212608vh;">
                <el-carousel arrow="always" indicator-position="outside" interval="5000">
                  <el-carousel-item v-for="item in success" :key="item">
                    <div class="card">
                      <div class="back">
                        <div v-left-in="{duration:800}" class="imgDesign">
                          <el-tooltip class="item" content="点击放大，看得更清晰！" effect="dark" placement="top">
                            <img :src="item.img" loading="lazy" @click="showModalTrue(item.img)"/>
                          </el-tooltip>
                        </div>
                        <div v-sliden-in="{duration:800}" class="titleDesign">
                          <div class="title1">{{ item.title1 }}</div>
                          <div class="title2" v-html="item.title2"></div>
                        </div>
                      </div>


                    </div>


                  </el-carousel-item>
                </el-carousel>
              </div>

            </div>
            <bottomTip></bottomTip></div>
        </div>
        <div v-if="showModal" class="modal-overlay" @click="closeModal">
          <div class="modal-content">
            <img :src="largeSrc" alt="Large Image" class="large-image">
          </div>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
    <div v-else>
      <div class="totalmakeHoleModel"  ref="totalModel">
        <div class="makeHole1">
          <div class="screen1">
            <kefuMobile @topMobile="topTopmobile"></kefuMobile>
            <headtip @showDialog="showPartner"></headtip>
            <!--      logo  you气通GPT社区 合作伙伴  登录-->

            <!--       油气通GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
            <div class="layer2">
              <div v-sliden-instep="{duration:800}" class="title1"><img loading="lazy" src="@/assets/AnxunLogo.png"></div>
              <div v-sliden-instep="{duration:1600}" class="title2" style="font-weight: bolder;font-size: 25px;margin-top: 15px;line-height: 30px;color:#F44336">
                智慧管网
              </div>
              <div v-sliden-instep="{duration:1600}" class="title2" style="font-weight: bolder;margin-top: 15px;font-size: 18px;line-height: 28px;color:#2c3e50;text-align: left">
                构建“地图+模型+数据+业务”一体化系统，实现管网的数智化、可视化运营管理。
              </div>
              <div v-sliden-instep="{duration:2400}" class="title4" style="font-weight: bolder;font-size: 18px;line-height: 28px;">油气数智，简单易行</div>

            </div>
            <div class="titlle3" @click="clickConsult('油气通')">
              产品咨询
            </div>
            <!--      平台大图-->
            <div class="layer3"></div>
          </div>
<!--          <div class="screenmake0">-->
<!--            <div v-sliden-in="{duration:800}" class="heighLight">智慧管网解决方案</div>-->
<!--            <div v-sliden-in="{duration:800}" class="heightLight2"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;智慧管网解决方案基于GIS和BIM技术，结合物联网、大数据、云计算和人工智能，提供全面的管网运维管理。以“数据整合、应用融合、业务联动、智能管理”为核心，构建“地图+模型+数据+业务”一体化系统，<span-->
<!--                style="color: rgb(255, 100, 0)">实现管网的数字化、在线化和智能化管理，解决传统管网可视性差、管理难度大、数据分散等问题，提升管网管理效率和智能化水平。</span>-->
<!--            </div>-->
<!--          </div>-->
          <div class="screenmake3">
            <div v-sliden-in="{duration:800}" class="heighLight" >您的收益</div>
            <div class="grid" style="height: 500px">
              <image-up-text-down-mobile v-for="elemnt in yourBenfits" :key="elemnt.title1"  :imagepath="require('@/assets'+elemnt.imagepath)" :title1="elemnt.title1" :title2="elemnt.title2"></image-up-text-down-mobile>
            </div>
          </div>
          <div class="screenmake7" style="padding-top: 0">
            <div v-sliden-in="{duration:800}" class="heighLight"> 业务痛点与挑战</div>
            <div class="grid">
              <div v-sliden-in="{duration:800}" class="card" style="margin-top: 10px">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/network03.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">数据应用与分析技术应用不足
                    </div>
                    <div class="title2">
                      管网监测技术逐步加强，但数据应用与分析技术应用仍有不足。
                    </div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/network02.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">.管网老化与安全隐患突出
                    </div>
                    <div class="title2">
                      管网老化与安全隐患问题突出，影响正常的供应服务，可能引发安全事故。
                    </div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/network01.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">运维管理内容复杂
                    </div>
                    <div class="title2">
                      运维管理内容复杂，人工管理与记录工作繁重，工作效率低。
                    </div>
                  </div>
                </div>


              </div>

            </div>

          </div>
          <div class="screenmake1" style="background-color: transparent">
            <div v-sliden-in="{duration:800}" class="heighLight"> 方案架构</div>
            <div class="grid">
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/guanwang11.png" style="border-radius: 9px">
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="screenmake6">
            <div v-sliden-in="{duration:800}" class="heighLight">核心功能</div>
            <div class="grid">
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="titleDesign">
                    <div class="title1">
                      GIS+BIM一体化管理
                    </div>
                    <div class="title2">
                      全生命周期管理：覆盖管网从设计到维护的全周期，确保数据一致。
                      可视化与监控：3D展示管网布局，实时监控设施状态。
                      精准定位与监控：支持设施精准定位和实时监控，快速识别异常。

                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="titleDesign">
                    <div class="title1">
                      数据整合与应用融合
                    </div>
                    <div class="title2">
                      多源数据整合：统一整合管网数据，消除信息孤岛，促进系统间数据共享。
                      大数据分析：深入分析实时与历史数据，支持智能调度和优化管理。
                      智能调度决策：基于大数据自动优化资源调度，多维度辅助决策，提升运营效率。
                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="titleDesign">
                    <div class="title1">
                      数字化运维管理
                    </div>
                    <div class="title2">
                      运营与维护：持续监控与预防性维护，减少故障，确保高效运行。
                      移动巡查：支持快速定位与响应，提升维护效率。
                      阴保监控：实时监测管网，预防腐蚀，确保结构安全，优化维护，降低成本。
                      光纤泄漏监测：实时检测微小泄漏，快速处理，降低风险。
                      无人机巡检：高效巡检复杂区域，精准识别异常。

                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
          <div class="screenmake2">
            <div v-sliden-in="{duration:800}" class="heighLight"> 技术特色</div>
            <div class="grid">
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign" style="width: 8vh;">
                    <img loading="lazy" src="@/assets/guanwang1.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">全方位多维度可视化
                    </div>
                    <div class="title2">
                      利用GIS+BIM技术，实现管网系统的全面可视化管理，从整体布局到局部细节实时呈现，提升管网的感知与监管能力。
                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/guanwang2.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">数据驱动的智能管理
                    </div>
                    <div class="title2">
                      通过大数据技术，综合分析管网各维度数据，提供智慧调度、故障预测和运营优化的科学决策支持。
                    </div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/guanwang3.png"
                         style="border-radius: 9px;height: 40px;margin-top: 2.5px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">一体化多终端支持
                    </div>
                    <div class="title2">
                      采用一体化管理平台，支持大屏监测、Web管理、移动巡查等多终端应用，满足不同用户需求，提升管理效率。
                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/guanwang4.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">高效智能泄漏巡检
                    </div>
                    <div class="title2">
                      结合光纤泄漏监测和无人机巡检，构建高效监测体系，确保管网安全稳定，降低故障率。
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="screenmake4">
            <div v-sliden-in="{duration:800}" class="heighLight">成功案例</div>
            <div v-sliden-in="{duration:800}" class="grid">
              <el-carousel arrow="always" indicator-position="outside" interval="5000">
                <el-carousel-item v-for="item in success" :key="item">
                  <div class="card">
                    <div class="back">
                      <div class="imgDesign">
                        <img :src="item.img" loading="lazy"/>
                      </div>
                      <div class="titleDesign">
                        <div class="title1">{{ item.title1 }}</div>
                        <div class="title2" v-html="item.title2"></div>
                      </div>
                    </div>


                  </div>


                </el-carousel-item>
              </el-carousel>
            </div>

          </div>
          <div ref="bottom">
            <bottomTip></bottomTip>
          </div>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
  </div>


</template>


<script>

import headtip from "@/views/headTip.vue";
import bottomTip from "@/views/bottomTip.vue";
import consultTip from "@/components/consultTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import {shareUrl} from "@/utils/vxshare";
import kefuMobile from "@/views/kefuMobile.vue";
import kefu from "@/views/kefu.vue";
import {vSlidenIn} from "@/utils/vSlidenIn";
import {vZoomIn} from "@/utils/vZoomIn";
import {vSlidenInstep} from "@/utils/vSlidenInstep";
import {vLeftIn} from "@/utils/vLeftIn";
import Anxunheader from "@/views/Anxun/components/Anxunheader.vue";
import ImageUpTextDown from "@/views/components/imageUpTextDown.vue";
import ImageUpTextDownMobile from "@/views/components/imageUpTextDownMobile.vue";
// import axios from "axios";
// import * as https from "https";

export default {
  directives: {
    slidenIn: vSlidenIn,
    zoomIn: vZoomIn,
    slidenInstep:vSlidenInstep,
    leftIn:vLeftIn
  },
  name: "",

  props: [],

  components: {
    ImageUpTextDownMobile,
    ImageUpTextDown, Anxunheader, PartnerTip, consultTip, headtip, bottomTip,kefu,kefuMobile},

  data() {
    return {
      yourBenfits:[
        {
          imagepath:'/storagebenefit2.png',
          title1:'增强管网可视性与安全保障',
          title2:'全面数字化监管与可视化管理使用户实时掌握管网运行状态，快速应对突发事件，显著提升安全性。'
        },
        {
          imagepath:'/stationbenefit2.png',
          title1:'优化资源利用与降低运维成本',
          title2:'智能调度与自动化管理提高资源利用效率，减少人为错误和数据孤岛带来的浪费，从而降低运维成本。'
        },
        {
          imagepath:'/stationbenefit3.png',
          title1:'提升决策支持与管理效能',
          title2:'数据驱动的分析与预测为管理层提供智慧调度决策支持，帮助制定科学的运营策略，提升管理效能。'
        },
        {
          imagepath:'/stationbenefit4.png',
          title1:'助力管网管理智慧化转型',
          title2:'助力企业从传统管理向智慧化转型，实现管网管理的数字化、智能化和精细化，满足未来需求。'
        },
      ],
      changeVisable:1,
      title: "",
      showModal: false,
      largeSrc: '',
      pageTitle: '智慧管网解决方案_安迅数智-油气通GPT',
      pageDescription: '安迅数智智慧安防解决方案，基于油田HSSE管理角度设计，提供全方位管理支持和全面安全保障，包括特种设备管理、特殊作业管理、承包商管理；全面安全控制：安全教育、风险分级管控、 隐患排查治理、重大危险源管理、重点现场监控、危化品监管。',
      pageKeywords: '智慧安防，风险排查与隐患治理，消防机器人，特殊作业管控，精确人员定位平台，智能 AI 安防，飞行器反制，智能AI巡检，智慧培训与应急模拟演练，智能消防，安迅数智，油气通GPT',
      scrollback: false,
      model: false,
      dialogconsult: false,
      dialogVisible: false,
      success: [{
        img: require("@/assets/guanwang22.png"),
        title1: "智慧管网",
        title2: "<li>国内某增压脱烃站外输管网巡检项目</li>",
      },
      ]
    }

  },


  methods: {
    topTopmobile() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      });
    },
    topTop() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      });
    },    changeheadVisable() {
      if (this.changeVisable === 1) {
        this.changeVisable = 0
      } else {
        this.changeVisable = 1
      }
      console.log("父组件", this.changeVisable)
    },
    getSearch(){
      this.$axios
          .get("/search/getAll",{
            // headers: {
            //   "Content-Type": "application/json",
            //   Authorization: "eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6IjUyNjZiN2QxLTNhOWUtNDBiOC05MGJlLTNjMTU1OGM3YmI3YiJ9.OsIDjD4UL8AZHu5j5KO-26IQwJu6uVvu59KNM18vuLmjzXMJFl-lX2vyyw_4L1AXC6wot6lpxhv_JZUVe1LokQ",
            // },
          })
          .then((res) => {
            console.log("search",res.data)

          })
          .catch((err) => {
            console.log(err);
          });
    },
    showModalTrue(item) {
      this.showModal = true;
      console.log(item)
      this.largeSrc = item
    },
    closeModal() {
      this.showModal = false;
    },
    clickConsult(item) {

      this.title = item
      this.dialogVisible = true;
      sessionStorage.setItem('title', item)
    },
    setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
    hide() {
      this.dialogconsult = false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    showPartner() {
      this.dialogconsult = true;
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    handleScroll() {
      if (this.model === false) {
        this.scrollHeight = this.$refs.total.scrollTop;
      } else {
        this.scrollHeight = this.$refs.totalModel.scrollTop;
      }

      if (this.scrollHeight < 100) {
        this.scrollTrue = true
      } else {
        this.scrollTrue = false
      }
      if (this.scrollHeight < 500) {
        this.scrollback = false
      } else {
        this.scrollback = true
      }
      console.log("gaodu", this.scrollHeight < 100)
    },
  },
  beforeDestroy() {

    this.$refs.total.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {



    this.$refs.total.addEventListener('scroll', this.handleScroll);
    document.title = this.pageTitle;
    this.setMetaTags();
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);

    if (screenWidth <= 700) {
      this.model = true
    } else {
      this.model = false
    }

    if (localStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
  },
  created() {
    this.getSearch()
    localStorage.setItem("home", 0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: '智慧管网解决方案_安迅数智-油气通GPT',
        pageDescription: '安迅数智智慧安防解决方案，基于油田HSSE管理角度设计，提供全方位管理支持和全面安全保障，包括特种设备管理、特殊作业管理、承包商管理；全面安全控制：安全教育、风险分级管控、 隐患排查治理、重大危险源管理、重点现场监控、危化品监管。',
        pageKeywords: '智慧安防，风险排查与隐患治理，消防机器人，特殊作业管控，精确人员定位平台，智能 AI 安防，飞行器反制，智能AI巡检，智慧培训与应急模拟演练，智能消防，安迅数智，油气通GPT',

      };
      shareUrl(shareData);
    }, 1000);
  },

}

</script>


<style lang="scss" scoped type="text/scss">
.totalmakeHole {
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;

  .makeHole1 {
    //z-index:: 3;
    position: relative;
    width: 100%;
    //height: 100vh;

 .topback{
    position: fixed;
    z-index:999;
      width: 100vw;
      padding: 0 0 0 2.86077411900635vw;
    }

    //.screen1 {
    //  //z-index:: 3;
    //  position: relative;
    //  width: 100%;
    //  height: 68vh;
    //  padding: 0 2.86077411900635vw 2.84301606922126vh 2.86077411900635vw;
    //  background-image: url("../../assets/banner.png");
    //  background-position: center;
    //  background-size: cover;
    //  background-repeat: no-repeat;
    //  background-color: transparent;
    //  /* Add any additional styling as needed */
    //
    //  .layer2 {
    //    position: absolute;
    //    top: 13.1495673671199vh;
    //    left: 9.17157712305026vw;
    //    .title11{
    //      margin: 3.8207663782vh auto;
    //      font-size: 2vw;
    //    }
    //    .title1 {
    //      width: fit-content;
    //      height: 5.77626699629172vh;
    //      //font-size: 3.50606585788562vw;
    //      font-family: Sarial, sans-serif, "Microsoft Yahei";
    //      font-weight: 400;
    //      color: #2f6eff;
    //      //line-height: 12.23733003708282vh;
    //    }
    //
    //    .title2 {
    //      //margin-top: 6.82076637824475vh;
    //      width: 33.45869439630272vw;
    //      height: fit-content;
    //      font-size: 1.0597920277vw;
    //      font-family: Sarial, sans-serif, "Microsoft Yahei";
    //      font-weight: 400;
    //      color: black;
    //      line-height: 4.31520395550062vh;
    //    }
    //
    //    .title4 {
    //      height: 8.77626699629172vh;
    //      font-size: 1.6060658579vw;
    //      font-family: Sarial, sans-serif, "Microsoft Yahei";
    //      font-weight: 400;
    //      color: #2f6eff;
    //      line-height: 9.31520395550062vh;
    //    }
    //
    //    .titlle3 {
    //      margin-top: 2.07911001236094vh;
    //      cursor: pointer;
    //      display: flex;
    //      justify-content: center;
    //      align-items: center;
    //      font-size: 1vw;
    //      font-family: Sarial, sans-serif, "Microsoft Yahei";
    //      font-weight: 400;
    //      color: #ffffff;
    //      line-height: 3.3374536465vh;
    //      text-align: center;
    //      border-radius: 9.1px;
    //      width: fit-content;
    //      padding: 2.4624227441vh 1.1989601386vw;
    //      height: 4.5624227441vh;
    //      background: #2f6eff;
    //      border: #2f6eff 1px solid;
    //
    //    }
    //
    //    .titlle3:hover {
    //
    //      color: #2168DB;
    //      border: #2168DB 1px solid;
    //      background: rgba(255, 255, 255, 0.5);
    //    }
    //  }
    //
    //  //.layer3 {
    //  //  position: absolute;
    //  //  top: 5.06798516687268vh;
    //  //  right: 11.61178509532062vw;
    //  //  width: 47.42923165800116vw;
    //  //  height: 72.80593325092707vh;
    //  //  background-image: url("../assets/GPTplant.png");
    //  //  background-size: 100% auto;
    //  //  background-position: center;
    //  //  background-repeat: no-repeat;
    //  //  background-color: transparent;
    //  //  opacity: 1;
    //  //}
    //}

    .screenmake0 {
      padding: 5.78739184177998vh 8.09763142692085vw 0 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        font-weight: bold;
        text-align: center;
        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin: 0 auto;
        text-align: left;
        height: fit-content;
        font-weight: bold;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 84vw;
        font-size: 1.15vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }
    }
    .screenmake6 {
      padding: 5.78739184177998vh 8.09763142692085vw 4.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;
      position: relative;

      .heighLight {
        margin: 0 auto;
        font-weight: bold;
        text-align: center;
        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin: 0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }
      .line{
        height: 1vh;
        width: 82%;
        left:50%;
        transform: translateX(-50%);
        border-bottom: dashed black 1px;
        position: absolute;
        top:20vh;
        z-index: 1;
      }
      .grid {
        z-index: 2;
        margin-top: 0vh;
        position: relative;
        display: grid;
        grid-template-columns:  minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        column-gap: 2vw;

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            //background: #EFF5FF;
            border-radius: 9px;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height: fit-content;
            overflow: hidden;

            .imgDesign {
              width: 8vh;
              height: 8vh;
              margin:0 auto 2vh auto;
              background-color: white;
              opacity: 1;
            }

            .titleDesign {
              padding: 0;
              width: 100%;
              //margin: 0 0 0 1.5vw;
              color: black;
              float: right;

              .title1 {
                margin-top: 0;
                text-align: center;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }

              .title2 {
                margin-top: 2vh;
                text-align: justify;
                line-height: 3.194066749vh;
                font-size: 0.9vw;
              }

              .title3 {
                display: grid;
                grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);

                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.8243212016vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake1 {
      padding: 7vh 11.09763142692085vw 5.78739184177998vh 11.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        font-weight: bold;
        text-align: center;
        height: 5.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin: 0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }

      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            //background: #EFF5FF;
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height: fit-content;
            overflow: hidden;

            .imgDesign {
              width: 100%;
              height: fit-content;
              opacity: 1;
            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }

    .screenmake5 {
      padding: 5.78739184177998vh 8.09763142692085vw 5.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: transparent;

      .heighLight {
        margin: 0 auto;
        font-weight: bold;
        text-align: center;
        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin: 0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }

      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;
        display: grid;
        grid-template-columns:  minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        column-gap: 0;

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            background: #c9d9f5;
            border-radius: 9px;
            //display: flex;
            position: relative;
            border: 1px solid white;
            box-shadow: 2px 0 12px 0px #c9d9f5;
            height: 38vh;
            padding: 2vh 1.5vw 3vh 1.5vw;
            overflow: hidden;

            .imgDesign {
              width: 5vh;
              margin: 0 auto 1vh auto;
              height: 5vh;
              opacity: 1;
            }

            .titleDesign {
              padding: 0;
              margin: 0 0 0 1.5vw;
              color: black;
              width: 100%;
              float: right;
              margin-top: 20px;

              .title1 {
                margin: 0 auto;
                text-align: center;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }

              .title2 {
                margin-top: 2vh;
                text-align: justify;
                line-height: 3.194066749vh;
                font-size: 0.9vw;
              }

              .title3 {
                display: grid;
                grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.8243212016vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }

    .screenmake2 {
      padding: 5.78739184177998vh 8.09763142692085vw 4.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      //background: transparent;
      background: white;

      .heighLight {
        margin: 0 auto;
        font-weight: bold;
        text-align: center;
        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin: 0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;
        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }

      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;
        display: grid;
        grid-template-columns:  minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        column-gap:0;

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            border-radius: 9px;
            background: white;
            //display: flex;
            position: relative;
            box-shadow: 2px 0 12px 0px #c9d9f5;
            height: 39vh;
            overflow: hidden;

            .imgDesign {
              width: 8vh;
              height: 8vh;
              opacity: 1;
              margin: 3vh auto;
              //background-image: url("../../assets/menage6.png");
            }

            .titleDesign {
              padding: 0;
              margin: 0 0 0 1.5vw;
              color: black;
              width: 85%;
              //float: right;
              .title1 {
                text-align: center;
                margin-top: 0;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }

              .title2 {
                margin-top: 2vh;
                text-align: justify;
                line-height: 3.194066749vh;
                font-size: 0.9vw;
              }

              .title3 {
                display: grid;
                grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);

                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.8243212016vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake11{
      padding: 5.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height:8.23733003708282vh;
        opacity: 1;
        font-weight: bold;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin:0 0.5vw;
        text-align: left;
        height: fit-content;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;
        font-size: 1.15vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }

      .heightLight3 {
        margin:3vh auto;
        text-align: center;
        height: fit-content;
        font-weight: bold;
        color: #2C3E50;
        line-height: 4.94437577255871vh;
        font-size: 1.5vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back{
            background: #eeeeef;
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height:52vh ;
            overflow:hidden;
            .imgDesign{
              width: fit-content;
              height:100%;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 1.5248454883vh 1.5248454883vw 2.5248454883vh 0;
              margin:auto 1vw auto auto;
              color: black;
              width: 40%;
              float: right;
              .title1{
                margin-top: 4vh;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }
              .title2{
                line-height: 3.3vh;
                font-size: 0.9vw;
              }
            }
          }
        }
        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
      .grid1{
        margin-top:2.78739184177998vh ;
        position: relative;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back{
            background: #c9d9f5;
            border-radius: 9px;
            position: relative;
            box-shadow: 2px 0 12px 0px #c9d9f5;
            height:25vh ;
            overflow:hidden;
            .imgDesign{
              width: fit-content;
              height:6vh;
              margin:3vh 1vw 0  2.5248454883vw;
              opacity: 1;
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .title1{
              margin-top: 4vh;
              font-size: 1.1709416522vw;
              font-weight: bold;
            }
            .titleDesign{
              padding: 0 1.5248454883vw 2.5248454883vh 1.5248454883vw;
              margin:0 auto;
              color: black;
              width:100%;
              float: right;

              .title2{
                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 1vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake3 {
      padding: 5.78739184177998vh 8.09763142692085vw 0.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      //height: 50vh;
      //height: fit-content;
      background: transparent;
      //background-image: url(@/assets/guanwang100.png);
      //background-image: url("../../assets/guanwang100.png");
      .heighLight {
        margin: 0 auto;
        font-weight: bold;
        text-align: center;
        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin: 0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }

      .grid {
        margin-top: 5.787392vh;
        position: relative;
        display: grid;
        height: 34vh;
        grid-template-columns:  minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        //grid-template-rows: 9vh 14vh 14vh 14vh;
        column-gap: 0;

        //.card {
        //  //cursor: pointer;
        //  width: 100%;
        //  height: fit-content;
        //  padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
        //  opacity: 1;
        //
        //  .back {
        //    background: rgba(0, 0, 0, 0);
        //    border-radius: 9px;
        //    position: relative;
        //    height: fit-content;
        //    overflow: hidden;
        //
        //    .imgDesign {
        //      width: 100%;
        //      height: fit-content;
        //      opacity: 1;
        //    }
        //
        //    .titleDesign {
        //      padding: 0;
        //      margin: 3vh auto;
        //      color: black;
        //      height: 20vh;
        //      width: 100%;
        //      float: right;
        //
        //      .title1 {
        //        margin: 0 auto;
        //        text-align: left;
        //        font-size: 1.1709416522vw;
        //        font-weight: bold;
        //      }
        //
        //      .title2 {
        //        margin: 2vh 0;
        //        width: 69vh;
        //        line-height: 3.194066749vh;
        //        font-size: 0.9vw;
        //      }
        //
        //      .title3 {
        //        display: grid;
        //        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
        //
        //        margin-top: 2vh;
        //        line-height: 3.194066749vh;
        //        font-size: 0.8243212016vw;
        //      }
        //
        //    }
        //
        //  }
        //}

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }

    .screenmake4 {
      padding: 5.78739184177998vh 5.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: #212a3c;

      .heighLight {
        display: flex;
        justify-content: center;
        text-align: center;
        height: 8.23733003708282vh;
        font-size: 2.2060658579vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: bold;
        color: white;
        line-height: 4.23733003708282vh;

      }

      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;

        .card {
          //cursor: pointer;
          width: 86%;
          margin: 0 auto;
          height: fit-content;
          padding: 0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;

          .back {
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height: 58vh;
            overflow: hidden;
            background: #19202e;

            .imgDesign {
              width: fit-content;
              height: 100%;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 1.5248454883vh 1.5248454883vw;
              margin: auto;
              text-align: center;
              color: white;
              width: fit-content;
              float: right;

              .title1 {
                text-align: left;
                font-size: 1.1709416522vw;
              }

              .title2 {
                margin-top: 3vh;
                line-height: 4vh;
                font-size: 0.9243212016vw;

                ::v-deep li {
                  margin-top: 5px;
                }
              ;
              }


            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 60vh !important;

        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          outline: 10vh;
          padding: 0;
          margin: 0;
          //cursor: pointer;

          border-radius: 50%;
          width: 55px;
          height: 55px;
          background-color: rgba(249, 250, 252, 0.2);
          border: rgba(255, 255, 255, 0.5) 1px solid;
          color: #fff;
          position: absolute;
          top: 50%;
          z-index: 100;
          transform: translateY(-50%);
          text-align: center;
          font-weight: 800;
          font-size: 25px;
        }
      }
    }
  }

  .modal-overlay {
    z-index: 999999999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  }

  .large-image {
    max-width: 100%;
    max-height: 80vh;
  }
}

.totalmakeHoleModel {
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;

  .makeHole1 {
    //z-index:: 3;
    position: relative;
    width: 100%;
    //height: 100vh;

    .screen1 {
      //z-index:: 3;
      position: relative;
      width: 100%;
      height: 670px;
      padding: 2.84301606922126vh 2.86077411900635vw 2.84301606922126vh 2.86077411900635vw;
      background-image: url("../../assets/bannerModel.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */

      .layer2 {
        position: absolute;
        top: 100px;
        left: 9.17157712305026vw;

        .title1 {
          width: 200px;;
          font-size: 3.50606585788562vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #2f6eff;
        }

        .title2 {
          margin-top: 5px;
          width: 95%;
          height: fit-content;
          font-size: 14px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #4B5A8B;
          line-height: 24px;
        }

        .title4 {
          margin-top: 15px;

          height: 22px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          font-size: 16px;
          color: #2f6dfd;
          line-height: 0px;
          font-style: normal;
          text-transform: none;
        }


      }

      .titlle3 {
        position: absolute;
        bottom: 30px;
        left: 50%;
        transform: translateX(-60%);
        font-size: 14px;
        font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 9.1px;
        width: fit-content;
        margin: 0 15px;
        padding: 5px 4.6875vw;
        height: 35px;
        color: white;
        background-color: #2f6eff;
        border: 1px solid #2f6eff;
        overflow: hidden;

      }

      //.layer3 {
      //  position: absolute;
      //  top: 5.06798516687268vh;
      //  right: 11.61178509532062vw;
      //  width: 47.42923165800116vw;
      //  height: 72.80593325092707vh;
      //  background-image: url("../assets/GPTplant.png");
      //  background-size: 100% auto;
      //  background-position: center;
      //  background-repeat: no-repeat;
      //  background-color: transparent;
      //  opacity: 1;
      //}
    }

    .screenmake0 {
      //z-index:: 10;
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding: 20px 4.6875vw 0 4.6875vw;
      background: white;

      .heighLight {
        text-align: center;
        height: 21px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;
      }

      .heightLight2 {
        padding: 19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size: 14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: bold;
        color: #2C3E50;
        line-height: 25px;
      }

    }
    .screenmake7{
      width: 100vw;
      height: fit-content;
      padding:20px  4.6875vw 20px 4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid{
        position: relative;
        height: fit-content;
        display: grid;
        margin-top: 10px;
        grid-template-columns: minmax(0,1fr) ;
        column-gap: 5px;
        .card{
          cursor: pointer;
          width: 100%;
          height:fit-content ;
          display: flex;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;
          .title1{
            margin: 5px auto;
            text-align: left;
            font-size: 16px;
            font-weight: bold;
          }
          .title2{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .title3{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .titleDesign{
            width: 90%;
            margin: 0 0 0 15px;
          }
          .back{
            background: white;
            border-radius: 9px;
            column-gap: 5px;
            position: relative;
            padding: 0 10px 0 10px;
            height:fit-content;
            display: flex;
            overflow:hidden;
            .imgDesign{
              width: 45px;
              height:45px;
              margin:0 auto 15px auto;
              opacity: 1;
            }


          }

        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake1 {
      width: 100vw;
      height: fit-content;
      padding: 20px 4.6875vw 20px 4.6875vw;
      background: transparent;

      .heighLight {
        text-align: center;
        height: 21px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;
      }

      .heightLight2 {
        margin: 19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size: 14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid {
        position: relative;
        height: fit-content;

        .card {
          cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .titleDesign {
            margin-top: 20px;
          }

          .back {
            background: white;
            border-radius: 9px;
            column-gap: 5px;
            position: relative;
            height: fit-content;
            margin-top: 15px;
            overflow: hidden;

            .imgDesign {
              width: 100%;
              //border: #666666 1px solid;
              height: fit-content;
              opacity: 1;

            }


          }

        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }

    .screenmake2 {
      //z-index:: 10;

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding: 20px 4.6875vw;
      background: transparent;

      .heighLight {
        text-align: center;
        height: 21px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;
      }

      .heightLight2 {
        margin: 19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size: 14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid {
        position: relative;
        height: fit-content;
        display: grid;
        margin-top: 10px;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
        column-gap: 5px;

        .card {
          cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .title1 {
            margin: 5px auto;
            text-align: center;
            font-size: 16px;
            font-weight: bold;
          }

          .title2 {
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }

          .title3 {
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }

          .titleDesign {
            margin-top: 10px;
          }

          .back {
            background: white;
            border-radius: 9px;
            column-gap: 5px;
            position: relative;
            padding: 15px 10px;
            height: 240px;
            margin-top: 5px;
            overflow: hidden;

            .imgDesign {
              width: 45px;
              height: 45px;
              margin: 0 auto 15px auto;
              opacity: 1;
            }


          }

        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }

    .screenmake3 {
      //z-index:: 10;

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding: 20px 4.6875vw;
      background: white;

      .heighLight {
        text-align: center;
        height: 21px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;
      }

      .heightLight2 {
        margin: 19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size: 14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid {
        position: relative;
        height: fit-content;
        display: grid;
        margin-top: 15px;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);

        row-gap: 15px;
        column-gap:10px;



        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }

    .screenmake4 {
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;

      padding: 20px 4.6875vw;
      background: black;

      .heighLight {
        text-align: center;
        height: 21px;
        opacity: 1;
        font-size: 21px;
        color: white;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;
      }

      .heightLight2 {
        margin: 19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size: 14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;

        .arrowButtonleft {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          left: -55px;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }

        .arrowButtonright {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          right: -55px;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }

        .card {
          cursor: pointer;
          width: 98%;
          margin: 0 auto;
          height: fit-content;
          padding: 0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;

          .back {
            border-radius: 9px;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height: fit-content;
            overflow: hidden;
            background: #19202e;

            .imgDesign {
              width: 100%;
              height: 194px;
              opacity: 1;

            }

            .titleDesign {
              padding: 15px 20px 25px 20px;
              color: white;
              width: 100%;
              float: right;

              .title1 {
                margin-top: 15px;
                font-size: 16px;
              }

              .title2 {
                margin-top: 15px;
                line-height: 22px;
                font-size: 14px;

                ::v-deep li {
                  margin-top: 5px;
                }
              ;
              }


            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 340px !important;

        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__arrow {
          display: block;
          //border: 1px solid white;
          outline: -5px;
          padding: 0;
          margin: -65px -10px;
          cursor: pointer;

          border-radius: 0;
          width: 25px;
          height: 48px;
          background: rgba(245, 246, 250, 0.8);
          color: #131313;
          position: absolute;
          top: 50%;
          //z-index:: 100;
          transform: translateY(-50%);
          text-align: center;
          font-size: 14px;
        }
      }
    }

    .screenmake6 {
      //z-index:: 10;

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding: 20px 4.6875vw;
      background: white;

      .heighLight {
        text-align: center;
        height: 21px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;
      }

      .heightLight2 {
        margin: 19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size: 14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 25px;
      }

      .grid {
        position: relative;
        height: fit-content;

        .card {
          cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .title1 {
            margin-top: 5px;
            font-size: 16px;
            font-weight: bold;
            text-align: center;
          }

          .title2 {

            width: auto;
            margin: 2vh;
            line-height: 22px;
            font-size: 14px;
          }

          .titleDesign {
            margin-top: 20px;
          }

          .title3 {
            margin-top: 5px;
            display: grid;
            grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
            line-height: 22px;
            font-size: 14px;
          }

          .back {
            margin-top: 15px;
            height: fit-content;
            background: #EFF5FF;
            border-radius: 9px;
            position: relative;
            box-shadow: 2px 0 12px 0px #c9d9f5;
            //height:580px ;
            overflow: hidden;

            .imgDesign {
              width: 100%;
              height: 195px;
              opacity: 1;
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }


          }

        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }

      .grid1 {
        position: relative;
        height: fit-content;
        display: grid;
        margin-top: 25px;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
        column-gap: 0;
        row-gap: 15px;

        .card {
          border-radius: 9px;
          cursor: pointer;
          width: 100%;
          height: 185px;
          background-color: white;
          padding: 5px 10px;
          opacity: 1;

          .title1 {
            height: 45px;
            justify-content: center;
            align-items: center;
            display: flex;
            font-size: 16px;
            font-weight: bold;
          }

          .title2 {
            margin: 0 5px;
            text-align: justify;
            line-height: 22px;
            font-size: 14px;
          }

          .titleDesign {
            margin-top: 10px;
          }

          .title3 {
            margin-top: 5px;
            display: grid;
            grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
            line-height: 22px;
            font-size: 14px;
          }

          .back {
            margin-top: 15px;
            border-radius: 9px;
            position: relative;
            //height:580px ;
            overflow: hidden;

            .imgDesign {
              width: 40px;
              height: 38px;
              margin: 0 10px 0 5px;
              opacity: 1;
              background-position: center;
              background-size: contain;
              background-repeat: no-repeat;
              background-color: transparent;
            }


          }

        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
  }

}
</style>
