<script>
import {vZoomIn} from '@/utils/vZoomIn.js'; // 确保路径正确
import {vSlidenInstep} from '@/utils/vSlidenInstep.js';
import {vSlidenIn} from "@/utils/vSlidenIn";
import {vLeftIn} from "@/utils/vLeftIn";
export default{
  directives: {
    slidenIn: vSlidenIn,
    zoomIn: vZoomIn,
    slidenInstep:vSlidenInstep,
    leftIn:vLeftIn
  },
  props: {
    title1: {
      type: String,
      default: '解决方案' // 设置默认值
    },
    title2:{
      type:String,
      default: '对油气场站的设备、安防、生产流程等进行全方位数智化管理'
    },
    // imagepath:{
    //   type:String,
    //   // default:"@/src/assets/ground1a.png"
    //   // default: '1'
    //   // default: '../../../assets/ground1a.png'
    //
    // }
  },
  methods:{
    clickConsult(){
      this.$emit('clickConsult1',true)
    }
  }
}
</script>

<template>
  <div class="screen1">

    <!--      logo  you气通GPT社区 合作伙伴  登录-->

    <!--       油气通GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
    <div class="layer2">
      <div class="title1"  style="font-size: unset;line-height: unset;"><img loading="lazy" src="@/assets/AnxunLogo.png"></div>
      <div class="title11" v-sliden-instep="{duration:1600}" style="font-weight: bolder;font-size: 2.5vw" >{{ title1 }}</div>
      <div class="title2" v-sliden-instep="{duration:2400}" style="font-weight: bolder;font-size: 1.3vw;width: 29.058694vw">
        {{ title2 }}
        <!--                  对场站的设备、巡检、安防和生产等方面，实现在线管理与实时监测预警 ，集成物联网（IoT）、大数据分析、人工智能（AI）、自动化设备等先进技术，实现作业标准化、生产可视化、运营一体化、研究协同化、管理精益化。-->
      </div>


    </div>
    <div v-sliden-instep="{duration:3000}" class="title4" style="font-weight: bolder;margin-top: 7vh">油气数智，简单易行</div>
    <div style="font-weight: bolder" class="titlle3" @click="clickConsult('油气通')">
      产品咨询
    </div>
    <!--      平台大图-->
    <div class="layer3"></div>
  </div>
</template>

<style scoped>
.screen1 {

  position: relative;
  width: 100%;
  height: 70vh;
  padding: 0 2.86077411900635vw 2.84301606922126vh 2.86077411900635vw;
  background-image: url("../../../assets/banner.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;
  /* Add any additional styling as needed */

  .layer2 {
    position: absolute;
    top: 13.1495673671199vh;
    left: 9.17157712305026vw;

    .title11{
      margin: 3.8207663782vh auto 3.0207663782vh auto;
      font-size: 2vw;

    }

    .title1 {
      width: fit-content;
      height: 5.77626699629172vh;
      font-size: 3.50606585788562vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #2f6eff;
      line-height: 12.23733003708282vh;
    }

    .title2 {
      //margin-top: 6.82076637824475vh;
      width: 33.45869439630272vw;
      height: fit-content;
      font-size: 1.0597920277vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: black;
      line-height: 4.31520395550062vh;
    }




  }
  .title4 {
    position: absolute;
    bottom: 9vh;
    left: 19vh;
    height: 8.77626699629172vh;
    font-size: 1.6060658579vw;
    font-family: Sarial, sans-serif, "Microsoft Yahei";
    font-weight: 400;
    color: #2f6eff;
    line-height: 9.31520395550062vh;
  }
  .titlle3 {
    position: absolute;
    bottom: 3vh;
    left: 19vh;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1vw;
    font-family: Sarial, sans-serif, "Microsoft Yahei";
    font-weight: 400;
    color: #ffffff;
    line-height: 3.3374536465vh;
    text-align: center;
    border-radius: 9.1px;
    width: fit-content;
    padding: 2.4624227441vh 1.1989601386vw;
    height: 4.5624227441vh;
    background: #2f6eff;
    border: #2f6eff 1px solid;

  }

  .titlle3:hover {

    color: #2168DB;
    border: #2168DB 1px solid;
    background: rgba(255, 255, 255, 0.5);
  }
}
</style>
